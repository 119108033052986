<template>
  <div>
    <b-modal
      id="modal-menu"
      no-close-on-backdrop
      centered
      ok-only
      hide-footer="true"
      :title="sidebarTitle"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group
              label="Name"
              invalid-feedback="Name is required."
              ref="name"
            >
              <b-form-input
                v-model.trim="myObj.title"
                placeholder="Enter page name"
                @focusout="CheckName()"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="status"
            >
              <v-select
                v-model="myObj.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="select status"
                @input="checkStatus()"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="text-right">
            <b-button variant="success" @click="Add()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-section"
      no-close-on-backdrop
      centered
      ok-only
      size="lg"
      hide-footer="true"
      :title="sidebarTitle"
    >
      <b-form class="" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="6">
            <b-form-group
              label="Section Type"
              invalid-feedback="Section Type is required."
              ref="type"
            >
              <v-select
                v-model="secObj.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                label="text"
                :reduce="(opt) => opt.value"
                :clearable="false"
                placeholder="select type"
                @input="CheckType()"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Status"
              invalid-feedback="Status is required."
              ref="sec_status"
            >
              <v-select
                v-model="secObj.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                label="text"
                :clearable="false"
                placeholder="select status"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <template v-if="secObj.type == 'Hero'">
            <b-col md="4">
              <b-form-group
                ref="image"
                label="Cover (1080 x 718)"
                invalid-feedback="Cover is required."
              >
                <b-media no-body>
                  <div v-if="logoloading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.fileProfile.click()"
                        v-if="logoloading == false"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                    </b-link>

                    <b-link v-if="logoloading == 'loaded'">
                      <div>
                        <i
                          @click="deleteLogo()"
                          style="margin-top: 70px; margin-left: 70px"
                          class="fa fa-trash fa-md text-danger position-absolute"
                        ></i>
                      </div>
                      <b-img
                        rounded
                        :src="heroObj.cover"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                      <br />
                    </b-link>

                    <input
                      type="file"
                      id="fileProfile"
                      hidden
                      ref="fileProfile"
                      @change="selectlogo()"
                      accept="image/*"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                ref="mob_image"
                label="Mobile Cover (521 x 927)"
                invalid-feedback="Mobile Cover is required."
              >
                <b-media no-body>
                  <div v-if="imgLoading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.mobProfile.click()"
                        v-if="imgLoading == false"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                    </b-link>

                    <b-link v-if="imgLoading == 'loaded'">
                      <div>
                        <i
                          @click="deleteMob()"
                          style="margin-top: 70px; margin-left: 70px"
                          class="fa fa-trash fa-md text-danger position-absolute"
                        ></i>
                      </div>
                      <b-img
                        rounded
                        :src="heroObj.mobileCover"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                      <br />
                    </b-link>

                    <input
                      type="file"
                      id="mobProfile"
                      hidden
                      ref="mobProfile"
                      @change="selectMob()"
                      accept="image/*"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                ref="hero_video"
                label="Banner Video"
                invalid-feedback="Banner Video is required."
              >
                <b-media no-body>
                  <div v-if="vidLoading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.vid.click()"
                        v-if="vidLoading == false"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                    </b-link>

                    <b-link v-if="vidLoading == 'loaded'">
                      <div>
                        <i
                          @click="deleteVid()"
                          style="margin-top: 70px; margin-left: 70px"
                          class="fa fa-trash fa-md text-danger position-absolute"
                        ></i>
                      </div>
                      <video
                        controls
                        style="height: 150px; width: 150px; object-fit: cover;border-radius: 5px;"
                      >
                        <source :src="heroObj.video" type="video/mp4" />
                      </video>

                      <br />
                    </b-link>

                    <input
                      type="file"
                      id="vid"
                      hidden
                      ref="vid"
                      @change="selectVid()"
                      accept="video/mp4"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Heading Line 1"
                invalid-feedback="Heading Line 1 is required."
                ref="hero_heading"
              >
                <b-form-input
                  v-model.trim="heroObj.title"
                  placeholder="Enter heading here"
                />
                <!-- @focusout="CheckHeroHeading()" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Heading Line 2"
                invalid-feedback="Heading Line 2 is required."
                ref="hero_heading2"
              >
                <b-form-input
                  v-model.trim="heroObj.title2"
                  placeholder="Enter heading here"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Button Title"
                invalid-feedback="Button Title is required."
                ref="hero_button"
              >
                <b-form-input
                  v-model.trim="heroObj.buttonTitle"
                  placeholder="Enter button title"
                />
                <!-- @focusout="CheckHeroButton()" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Redirect To"
                invalid-feedback="Redirect To is required."
                ref="hero_redirect"
              >
                <v-select
                  v-model="heroObj.actionType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="actionOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select"
                  @input="CheckHeroRedirect()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="heroObj.actionType == 'page'">
              <b-form-group
                label="Page"
                invalid-feedback="Page is required."
                ref="hero_page"
              >
                <v-select
                  v-model="heroObj.pageID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="Select page"
                  @input="CheckHeroPage()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-else-if="heroObj.actionType == 'url'">
              <b-form-group
                label="URL"
                invalid-feedback="URL is required."
                ref="hero_url"
              >
                <b-form-input
                  v-model.trim="heroObj.url"
                  placeholder="Enter url"
                  @focusout="CheckHeroUrl()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Take action'">
            <b-col md="6">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="action_heading"
              >
                <b-form-input
                  v-model.trim="actionObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckAcTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Title 2"
                invalid-feedback="Title 2 is required."
                ref="action_heading2"
              >
                <b-form-input
                  v-model.trim="actionObj.title2"
                  placeholder="Enter title here"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Details"
                invalid-feedback="Details is required."
                ref="action_details"
              >
                <b-form-textarea
                  v-model.trim="actionObj.details"
                  placeholder="Enter details here"
                  @focusout="CheckAcDetails()"
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              v-if="actionObj.buttonData.length == 0"
              class="text-center mt-1"
            >
              <h5>Add Action Buttons</h5>
              <b-button
                @click="AddVal()"
                variant="primary"
                class="btn-icon rounded-circle"
              >
                <feather-icon size="18" icon="PlusIcon" />
              </b-button>
            </b-col>
            <b-col
              v-else
              md="12"
              v-for="(item, ind) in actionObj.buttonData"
              :key="ind"
            >
              <b-row>
                <b-col md="4" class="pr-md-0">
                  <b-form-group
                    :label="`Button ${ind + 1}`"
                    invalid-feedback="Button Title is required."
                  >
                    <b-form-input
                      v-model.trim="item.title"
                      placeholder="Enter button title"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="pr-md-0">
                  <b-form-group
                    label="Redirect To"
                    invalid-feedback="Redirect To is required."
                  >
                    <v-select
                      v-model="item.actionType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="actionOptions"
                      :reduce="(val) => val.value"
                      label="text"
                      :clearable="false"
                      placeholder="Select"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" v-if="item.actionType == 'page'" class="pr-md-0">
                  <b-form-group
                    label="Page"
                    invalid-feedback="Page is required."
                  >
                    <v-select
                      v-model="item.pageID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      label="title"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      placeholder="Select page"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="3"
                  v-else-if="item.actionType == 'url'"
                  class="pr-md-0"
                >
                  <b-form-group label="URL" invalid-feedback="URL is required.">
                    <b-form-input
                      v-model.trim="item.url"
                      placeholder="Enter url"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2" class="mt-2">
                  <b-button
                    v-if="ind == actionObj.buttonData.length - 1"
                    @click="AddVal()"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    @click="removeVal(ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Projects'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="project_title"
              >
                <b-form-input
                  v-model.trim="projObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckProjTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- label="Projects" -->
              <b-form-group
                invalid-feedback="Projects is required."
                ref="project_sel"
              >
                <div class="d-flex justify-content-between flex-wrap pb-50">
                  <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                    >Projects</label
                  >
                  <div class="d-flex flex-wrap">
                    <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                      >Autoplay:</label
                    >
                    <b-form-checkbox
                      v-model="projObj.autoplay"
                      switch
                      class="mr-0 ml-1"
                    />
                    <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                      >Show in Blocks:</label
                    >
                    <b-form-checkbox
                      v-model="projObj.blocks"
                      switch
                      class="mr-0 ml-1"
                    />
                  </div>
                </div>
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="projObj.projects"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="projectOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="select projects"
                  @input="CheckProjSel()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Video'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="video_title"
              >
                <b-form-input
                  v-model.trim="videoObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckVidTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Video Link"
                invalid-feedback="Video Link is required."
                ref="video_link"
              >
                <b-form-input
                  v-model.trim="videoObj.link"
                  placeholder="Enter link"
                  @focusout="CheckVidLink()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Fund Raiser'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="fund_title"
              >
                <b-form-input
                  v-model.trim="fundObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckFundTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Fund Raisers"
                invalid-feedback="Fund Raisers is required."
                ref="fund_sel"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="fundObj.fundIDs"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="fundOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="select funds"
                  @input="CheckFundSel()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Blogs'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="blog_title"
              >
                <b-form-input
                  v-model.trim="blogObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckBlogTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Blogs"
                invalid-feedback="Blogs is required."
                ref="blog_sel"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="blogObj.blogIDs"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="blogOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="select blogs"
                  @input="CheckBlogSel()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Split Section'">
            <b-col md="6">
              <b-form-group
                ref="sp_image"
                label="Image / Video"
                invalid-feedback="Image / Video is required."
              >
                <b-media no-body>
                  <div v-if="spLoading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.spImage.click()"
                        v-if="spLoading == false"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                    </b-link>

                    <b-link v-if="spLoading == 'loaded'">
                      <div>
                        <i
                          @click="deleteSp()"
                          style="margin-top: 70px; margin-left: 70px;z-index: 10;"
                          class="fa fa-trash fa-md text-danger position-absolute"
                        ></i>
                      </div>
                      <video
                        v-if="isVideo(splitObj.image)"
                        controls
                        style="height: 180px; width: 180px; object-fit: cover;border-radius: 5px;"
                      >
                        <source :src="splitObj.image" type="video/mp4" />
                      </video>
                      <b-img
                        v-else
                        rounded
                        :src="splitObj.image"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                      <br />
                    </b-link>

                    <input
                      type="file"
                      id="spImage"
                      hidden
                      ref="spImage"
                      @change="selectSp()"
                      accept="image/*,video/mp4"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div class="d-flex">
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Image at Right:</label
                >
                <b-form-checkbox
                  v-model="splitObj.imageRight"
                  switch
                  class="mr-0 ml-1"
                />
              </div>
              <div class="d-flex mt-1">
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Animation:</label
                >
                <b-form-checkbox
                  v-model="splitObj.animation"
                  switch
                  class="mr-0 ml-1"
                />
              </div>
            </b-col>
            <b-col md="12">
              <b-form-group
                invalid-feedback="Title is required."
                ref="sp_title"
              >
                <div class="d-flex justify-content-between flex-wrap pb-50">
                  <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                    >Title</label
                  >
                  <div class="d-flex">
                    <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                      >Title at right:</label
                    >
                    <b-form-checkbox
                      v-model="splitObj.titleRight"
                      switch
                      class="mr-0 ml-1"
                    />
                  </div>
                </div>
                <b-form-input
                  v-model.trim="splitObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckSpTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Details"
                invalid-feedback="Details is required."
                ref="sp_details"
              >
                <div id="quill-toolbar" class="d-flex border-bottom-0">
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model="splitObj.details"
                  :options="editorOption"
                  class="border-top-0"
                  @focusout="CheckSpDetails()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Button Title"
                invalid-feedback="Button Title is required."
                ref="sp_button"
              >
                <b-form-input
                  v-model.trim="splitObj.buttonTitle"
                  placeholder="Enter button title"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Redirect To"
                invalid-feedback="Redirect To is required."
                ref="sp_redirect"
              >
                <v-select
                  v-model="splitObj.actionType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="actionOptions"
                  :reduce="(val) => val.value"
                  label="text"
                  :clearable="false"
                  placeholder="Select"
                  @input="CheckSpAction()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="splitObj.actionType == 'page'">
              <b-form-group
                label="Page"
                invalid-feedback="Page is required."
                ref="sp_page"
              >
                <v-select
                  v-model="splitObj.pageID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="Select page"
                  @input="CheckSpPage()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" v-else-if="splitObj.actionType == 'url'">
              <b-form-group
                label="URL"
                invalid-feedback="URL is required."
                ref="sp_url"
              >
                <b-form-input
                  v-model.trim="splitObj.url"
                  placeholder="Enter url"
                  @focusout="CheckSpUrl()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Info'">
            <b-col md="6">
              <b-form-group
                ref="info_image"
                label="Watermark Image"
                invalid-feedback="Watermark Image is required."
              >
                <b-media no-body>
                  <div v-if="infoLoading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.infoImage.click()"
                        v-if="infoLoading == false"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                    </b-link>

                    <b-link v-if="infoLoading == 'loaded'">
                      <div>
                        <i
                          @click="deleteInfo()"
                          style="margin-top: 70px; margin-left: 70px"
                          class="fa fa-trash fa-md text-danger position-absolute"
                        ></i>
                      </div>
                      <b-img
                        rounded
                        :src="infoObj.watermark"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                      <br />
                    </b-link>

                    <input
                      type="file"
                      id="infoImage"
                      hidden
                      ref="infoImage"
                      @change="selectInfo()"
                      accept="image/*"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div class="d-flex">
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Colored Section:</label
                >
                <b-form-checkbox
                  v-model="infoObj.isColored"
                  switch
                  class="mr-0 ml-1"
                />
              </div>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="info_title"
              >
                <b-form-input
                  v-model.trim="infoObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckInfoTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Details"
                invalid-feedback="Details is required."
                ref="info_details"
              >
                <!-- <b-form-textarea
                  v-model.trim="infoObj.details"
                  placeholder="Enter details here"
                  @focusout="CheckInfoDetails()"
                  rows="8"
                /> -->

                <div id="quill-toolbar" class="d-flex border-bottom-0">
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model="infoObj.details"
                  :options="editorOption"
                  class="border-top-0"
                  @focusout="CheckInfoDetails()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Contact Form'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="c_title"
              >
                <b-form-input
                  v-model.trim="contactObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckCTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                invalid-feedback="Type is required."
                ref="c_type"
              >
                <v-select
                  v-model="contactObj.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="formOptions"
                  label="text"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  placeholder="select type"
                  @input="CheckCType()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Partners'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="part_title"
              >
                <b-form-input
                  v-model.trim="partObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckPTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Partners"
                invalid-feedback="Partners is required."
                ref="part_sel"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="partObj.pIDs"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="partOptions"
                  label="id"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="select partners"
                  @input="CheckPSelected()"
                >
                  <template #option="{ image }">
                    <b-img
                      rounded
                      :src="image"
                      style="height: 60px; width: 100px; object-fit: contain"
                    />
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Priorities'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="prior_title"
              >
                <b-form-input
                  v-model.trim="priorObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckPrTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Priorities"
                invalid-feedback="Priorities is required."
                ref="prior_sel"
              >
                <v-select
                  multiple
                  :closeOnSelect="false"
                  v-model="priorObj.priorIDs"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="priorOptions"
                  label="title"
                  :reduce="(val) => val.id"
                  :clearable="false"
                  placeholder="select priority"
                  @input="CheckPrSel()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Donation Banner'">
            <b-col md="12">
              <b-form-group
                ref="db_image"
                label="Image"
                invalid-feedback="Image is required."
              >
                <b-media no-body>
                  <div v-if="dbLoading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                        @click="$refs.dbImg.click()"
                        v-if="dbLoading == false"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                    </b-link>

                    <b-link v-if="dbLoading == 'loaded'">
                      <div>
                        <i
                          @click="deleteDImg()"
                          style="margin-top: 70px; margin-left: 70px"
                          class="fa fa-trash fa-md text-danger position-absolute"
                        ></i>
                      </div>
                      <b-img
                        rounded
                        :src="donateObj.image"
                        style="height: 150px; width: 150px; object-fit: cover"
                      />
                      <br />
                    </b-link>

                    <input
                      type="file"
                      id="dbImg"
                      hidden
                      ref="dbImg"
                      @change="selectDImg()"
                      accept="image/*"
                    />
                  </b-media-aside>
                </b-media>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="db_title"
              >
                <b-form-input
                  v-model.trim="donateObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckDTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Amount"
                invalid-feedback="Amount is required."
                ref="db_amount"
              >
                <b-form-input
                  v-model="donateObj.initialAmount"
                  placeholder="Enter amount"
                  type="number"
                  @focusout="checkDAmount()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Monthly Donation Link"
                invalid-feedback="Monthly Link is required."
                ref="db_link"
              >
                <b-form-input
                  v-model.trim="donateObj.link"
                  placeholder="Enter monthly donation link"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Donation Card'">
            <b-col md="6">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="dc_title"
              >
                <b-form-input
                  v-model.trim="dcardObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckDCTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Amount"
                invalid-feedback="Amount is required."
                ref="dc_amount"
              >
                <b-form-input
                  v-model="dcardObj.initialAmount"
                  placeholder="Enter amount"
                  type="number"
                  @focusout="checkDCAmount()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Monthly Donation Link"
                invalid-feedback="Monthly Link is required."
                ref="dc_link"
              >
                <b-form-input
                  v-model.trim="dcardObj.link"
                  placeholder="Enter monthly donation link"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Quick Donation'">
            <b-col md="6">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="q_title"
              >
                <b-form-input
                  v-model.trim="quickObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckQTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Monthly Donation Link"
                invalid-feedback="Monthly Link is required."
                ref="q_link"
              >
                <b-form-input
                  v-model.trim="quickObj.link"
                  placeholder="Enter monthly donation link"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Faqs'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="faq_title"
              >
                <b-form-input
                  v-model.trim="faqObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckFaqTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" v-for="(item, ind) in faqObj.faqs" :key="ind">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Question"
                    invalid-feedback="Question is required."
                  >
                    <b-form-input
                      v-model.trim="item.text"
                      placeholder="Enter question"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Answer"
                    invalid-feedback="Answer is required."
                  >
                    <!-- <b-form-input
                      v-model.trim="item.answer"
                      placeholder="Enter answer"
                    />  -->
                    <div
                      :id="`quill-toolbar-${ind}`"
                      class="d-flex border-bottom-0"
                    >
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-list" value="ordered" />
                      <button class="ql-list" value="bullet" />
                      <button class="ql-link" />
                    </div>
                    <quill-editor
                      id="quil-content"
                      v-model="item.answer"
                      :options="getOptions(ind)"
                      class="border-top-0"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="text-center">
                  <b-button
                    v-if="ind == faqObj.faqs.length - 1"
                    @click="AddFaq()"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="faqObj.faqs.length > 1"
                    @click="removeFaq(ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Carousel'">
            <b-col md="12" class="mb-1">
              <div class="d-flex flex-wrap">
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Autoplay:</label
                >
                <b-form-checkbox
                  v-model="cData.autoplay"
                  switch
                  class="mr-0 mx-1 mb-50"
                  inline
                />
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Pagination:</label
                >
                <b-form-checkbox
                  v-model="cData.pagination"
                  switch
                  class="mr-0 mx-1 mb-50"
                  inline
                />
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Arrows:</label
                >
                <b-form-checkbox
                  v-model="cData.arrows"
                  switch
                  class="mr-0 ml-1"
                  inline
                />
              </div>
            </b-col>
            <b-col md="12">
              <b-row
                v-for="(item, ind) in cData.slideData"
                :key="ind"
                class="mb-1"
              >
                <b-col md="6">
                  <b-form-group
                    label="Image"
                    invalid-feedback="Image is required."
                  >
                    <b-media no-body>
                      <div v-if="caroLoading && currentIndex == ind">
                        <b-spinner
                          variant="primary"
                          type="grow"
                          class="m-5"
                          label="Spinning"
                        ></b-spinner>
                      </div>

                      <b-media-aside v-else>
                        <b-link v-if="item.image">
                          <div>
                            <i
                              @click="item.image = ''"
                              style="margin-top: 70px; margin-left: 70px"
                              class="fa fa-trash fa-md text-danger position-absolute"
                            ></i>
                          </div>
                          <b-img
                            rounded
                            :src="item.image"
                            style="height: 150px; width: 150px; object-fit: cover"
                          />
                          <br />
                        </b-link>
                        <b-link v-else>
                          <b-img
                            rounded
                            src="https://cdn.cloudious.net/file-1678189226453-406060527.png"
                            @click="$refs['cImage' + ind][0].click()"
                            style="height: 150px; width: 150px; object-fit: cover"
                          />
                        </b-link>
                        <input
                          type="file"
                          id="cImage"
                          hidden
                          :ref="'cImage' + ind"
                          @change="selectCaro(item, ind)"
                          accept="image/*"
                        />
                      </b-media-aside>
                    </b-media>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Heading"
                    invalid-feedback="Heading is required."
                  >
                    <b-form-input
                      v-model.trim="item.heading"
                      placeholder="Enter heading"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Button Title"
                    invalid-feedback="Button Title is required."
                  >
                    <b-form-input
                      v-model.trim="item.buttonTitle"
                      placeholder="Enter button title"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Redirect To"
                    invalid-feedback="Redirect To is required."
                  >
                    <v-select
                      v-model="item.actionType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="actionOptions"
                      :reduce="(val) => val.value"
                      label="text"
                      :clearable="false"
                      placeholder="Select"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="item.actionType == 'page'"
                    label="Page"
                    invalid-feedback="Page is required."
                  >
                    <v-select
                      v-model="item.pageID"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      label="title"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      placeholder="Select page"
                    />
                  </b-form-group>
                  <b-form-group
                    v-else-if="item.actionType == 'url'"
                    label="URL"
                    invalid-feedback="URL is required."
                  >
                    <b-form-input
                      v-model.trim="item.url"
                      placeholder="Enter url"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="text-center">
                  <b-button
                    v-if="ind == cData.slideData.length - 1"
                    @click="addSlide()"
                    variant="primary"
                    class="btn-icon mr-50 rounded-circle"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="cData.slideData.length > 1"
                    @click="removeSlide(ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                invalid-feedback="Images are required."
                ref="car_img"
              >
                <b-button
                  variant="primary"
                  :disabled="caroLoading"
                  @click="$refs.cImage.click()"
                >
                  <b-spinner
                    v-if="caroLoading"
                    small
                    variant="light"
                    type="grow"
                  />
                  <span v-else><feather-icon icon="PlusIcon" /> Images</span>
                  <input
                    type="file"
                    id="cImage"
                    hidden
                    ref="cImage"
                    @change="selectCaro()"
                    accept="image/*"
                    multiple
                  />
                </b-button>
              </b-form-group>
            </b-col>
            <b-col md="12" class="scrolling-wrapper mt-1">
              <div
                class="moreImg"
                v-for="(item, ind) in cData.images"
                :key="ind"
              >
                <b-img
                  rounded
                  :src="item"
                  style="height: 150px; width: 150px; object-fit: cover"
                />
                <feather-icon
                  icon="XIcon"
                  size="18"
                  class="my-close text-danger"
                  @click="deleteCaro(ind)"
                />
              </div>
            </b-col> -->
          </template>

          <template v-else-if="secObj.type == 'Colored Box'">
            <b-col md="12">
              <b-form-group
                label="Details"
                invalid-feedback="Details is required."
                ref="box_details"
              >
                <b-form-textarea
                  v-model.trim="boxObj.details"
                  placeholder="Enter details here"
                  @focusout="CheckBoxDetails()"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Bank Details'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="bank_title"
              >
                <b-form-input
                  v-model.trim="bankObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckBankTitle()"
                />
              </b-form-group>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Overview'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="ov_title"
              >
                <b-form-input
                  v-model.trim="overObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckOverTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Details"
                invalid-feedback="Details is required."
                ref="ov_details"
              >
                <div :id="`quill-toolbar-${0}`" class="d-flex border-bottom-0">
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model.trim="overObj.details"
                  :options="getOptions(0)"
                  class="border-top-0"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Colored Details"
                invalid-feedback="Details is required."
              >
                <div :id="`quill-toolbar-${1}`" class="d-flex border-bottom-0">
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model.trim="overObj.coloredDetails"
                  :options="getOptions(1)"
                  class="border-top-0"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="More Details"
                invalid-feedback="Details is required."
              >
                <div :id="`quill-toolbar-${2}`" class="d-flex border-bottom-0">
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model.trim="overObj.moreDetails"
                  :options="getOptions(2)"
                  class="border-top-0"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Light Details"
                invalid-feedback="Details is required."
              >
                <div :id="`quill-toolbar-${3}`" class="d-flex border-bottom-0">
                  <button class="ql-header" value="2" />
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-list" value="ordered" />
                  <button class="ql-list" value="bullet" />
                  <button class="ql-link" />
                </div>
                <quill-editor
                  id="quil-content"
                  v-model.trim="overObj.lightDetails"
                  :options="getOptions(3)"
                  class="border-top-0"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="FAQ Title"
                invalid-feedback="Title is required."
                ref="ov_ftitle"
              >
                <b-form-input
                  v-model.trim="overObj.faqtitle"
                  placeholder="Enter title here"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="overObj.faqs.length == 0" md="12" class="text-center">
              <b-button
                @click="AddOvFaq()"
                variant="primary"
                class="btn-icon rounded-circle mr-50"
              >
                <feather-icon size="18" icon="PlusIcon" />
              </b-button>
            </b-col>
            <b-col md="12" v-for="(item, ind) in overObj.faqs" :key="ind">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Question"
                    invalid-feedback="Question is required."
                  >
                    <b-form-input
                      v-model.trim="item.text"
                      placeholder="Enter question"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Answer"
                    invalid-feedback="Answer is required."
                  >
                    <div
                      :id="`quill-toolbar-f${ind}`"
                      class="d-flex border-bottom-0"
                    >
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-list" value="ordered" />
                      <button class="ql-list" value="bullet" />
                      <button class="ql-link" />
                    </div>
                    <quill-editor
                      id="quil-content"
                      v-model="item.answer"
                      :options="getOptions(`f${ind}`)"
                      class="border-top-0"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="text-center">
                  <b-button
                    v-if="ind == overObj.faqs.length - 1"
                    @click="AddOvFaq()"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    @click="removeOvFaq(ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Stats'">
            <b-col md="12">
              <b-form-group
                label="Title"
                invalid-feedback="Title is required."
                ref="st_title"
              >
                <b-form-input
                  v-model.trim="statsObj.title"
                  placeholder="Enter title here"
                  @focusout="CheckSTitle()"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" v-for="(item, ind) in statsObj.stats" :key="ind">
              <b-row>
                <b-col md="6" class="">
                  <b-form-group
                    label="Text"
                    invalid-feedback="Text is required."
                  >
                    <b-form-input
                      v-model.trim="item.text"
                      placeholder="Enter text"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" class="">
                  <b-form-group
                    label="Count"
                    invalid-feedback="Count is required."
                  >
                    <b-form-input
                      type="number"
                      v-model="item.count"
                      placeholder="Enter count"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="2" class="mt-2">
                  <b-button
                    v-if="ind == statsObj.stats.length - 1"
                    @click="AddSt()"
                    variant="primary"
                    class="btn-icon rounded-circle mr-50"
                  >
                    <feather-icon size="18" icon="PlusIcon" />
                  </b-button>
                  <b-button
                    v-if="statsObj.stats.length > 1"
                    @click="removeSt(ind)"
                    variant="outline-danger"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon size="18" icon="XIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <template v-else-if="secObj.type == 'Column'">
            <b-col md="6">
              <b-form-group
                label="No. of Columns"
                invalid-feedback="No. of Columns is required."
                ref="col_count"
              >
                <v-select
                  v-model="colObj.columns"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="columnOptions"
                  :clearable="false"
                  placeholder="select"
                />
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              v-for="(item, ind) in colObj.sections.slice(0, colObj.columns)"
              :key="ind"
            >
              <hr />
              <h4 class="mb-0">Column {{ ind + 1 }}</h4>
              <hr />
              <b-row class="">
                <b-col md="6">
                  <b-form-group
                    label="Section Type"
                    invalid-feedback="Section Type is required."
                    :ref="`col_type${ind}`"
                  >
                    <v-select
                      v-model="item.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="columnTypes"
                      label="text"
                      :reduce="(opt) => opt.value"
                      :clearable="false"
                      placeholder="select type"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row v-if="item.type == 'Donation Card'">
                <b-col md="6">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    :ref="`col_c_title${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.card.title"
                      placeholder="Enter title here"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Amount"
                    invalid-feedback="Amount is required."
                    :ref="`col_c_amount${ind}`"
                  >
                    <b-form-input
                      v-model="item.dataObj.card.initialAmount"
                      placeholder="Enter amount"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
              </b-row> -->

              <b-row v-if="item.type == 'Video'">
                <b-col md="12">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    :ref="`col_v_title${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.video.title"
                      placeholder="Enter title here"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Video Link"
                    invalid-feedback="Video Link is required."
                    :ref="`col_v_link${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.video.link"
                      placeholder="Enter link"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-else-if="item.type == 'Info'">
                <b-col md="6">
                  <div class="d-flex">
                    <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                      >Colored Section:</label
                    >
                    <b-form-checkbox
                      v-model="item.dataObj.info.isColored"
                      switch
                      class="mr-0 ml-1"
                    />
                  </div>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    :ref="`col_in_title${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.info.title"
                      placeholder="Enter title here"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Details"
                    invalid-feedback="Details is required."
                    :ref="`col_in_details${ind}`"
                  >
                    <div
                      :id="`quill-toolbar-in-${ind}`"
                      class="d-flex border-bottom-0"
                    >
                      <button class="ql-header" value="2" />
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-list" value="ordered" />
                      <button class="ql-list" value="bullet" />
                      <button class="ql-link" />
                    </div>
                    <quill-editor
                      id="quil-content"
                      v-model="item.dataObj.info.details"
                      :options="getOptions(`in-${ind}`)"
                      class="border-top-0"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-else-if="item.type == 'Contact Form'">
                <b-col md="12">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    :ref="`col_cf_title${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.contact.title"
                      placeholder="Enter title here"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Type"
                    invalid-feedback="Type is required."
                    :ref="`col_cf_type${ind}`"
                  >
                    <v-select
                      v-model="item.dataObj.contact.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="formOptions"
                      label="text"
                      :reduce="(val) => val.value"
                      :clearable="false"
                      placeholder="select type"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else-if="item.type == 'Partners'">
                <b-col md="12">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    :ref="`col_part_title${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.partners.title"
                      placeholder="Enter title here"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Partners"
                    invalid-feedback="Partners is required."
                    :ref="`col_part_ids${ind}`"
                  >
                    <v-select
                      multiple
                      :closeOnSelect="false"
                      v-model="item.dataObj.partners.pIDs"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="partOptions"
                      label="id"
                      :reduce="(val) => val.id"
                      :clearable="false"
                      placeholder="select partners"
                    >
                      <template #option="{ image }">
                        <b-img
                          rounded
                          :src="image"
                          style="height: 60px; width: 100px; object-fit: contain"
                        />
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-else-if="item.type == 'Faqs'">
                <b-col md="12">
                  <b-form-group
                    label="Title"
                    invalid-feedback="Title is required."
                    :ref="`col_f_title${ind}`"
                  >
                    <b-form-input
                      v-model.trim="item.dataObj.faqs.title"
                      placeholder="Enter title here"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  v-for="(ff, index) in item.dataObj.faqs.faqs"
                  :key="index"
                >
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Question"
                        invalid-feedback="Question is required."
                      >
                        <b-form-input
                          v-model.trim="ff.text"
                          placeholder="Enter question"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Answer"
                        invalid-feedback="Answer is required."
                      >
                        <div
                          :id="`quill-toolbar-${ind}-${index}`"
                          class="d-flex border-bottom-0"
                        >
                          <button class="ql-bold" />
                          <button class="ql-italic" />
                          <button class="ql-underline" />
                          <button class="ql-list" value="ordered" />
                          <button class="ql-list" value="bullet" />
                          <button class="ql-link" />
                        </div>
                        <quill-editor
                          id="quil-content"
                          v-model="ff.answer"
                          :options="getOptions(`${ind}-${index}`)"
                          class="border-top-0"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12" class="text-center">
                      <b-button
                        v-if="index == item.dataObj.faqs.faqs.length - 1"
                        @click="AddColFaq(ind)"
                        variant="primary"
                        class="btn-icon rounded-circle mr-50"
                      >
                        <feather-icon size="18" icon="PlusIcon" />
                      </b-button>
                      <b-button
                        v-if="item.dataObj.faqs.faqs.length > 1"
                        @click="removeColFaq(ind, index)"
                        variant="outline-danger"
                        class="btn-icon rounded-circle"
                      >
                        <feather-icon size="18" icon="XIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row v-else-if="item.type == 'Colored Box'">
                <b-col md="12">
                  <b-form-group
                    label="Details"
                    invalid-feedback="Details is required."
                    :ref="`col_b_details${ind}`"
                  >
                    <b-form-textarea
                      v-model.trim="item.dataObj.box.details"
                      placeholder="Enter details here"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </template>

          <b-col md="12" class="text-right mt-1">
            <b-button
              variant="success"
              @click="saveSection()"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-card no-body v-if="showSections">
      <b-row class="p-2">
        <b-col md="12">
          <b-media vertical-align="center">
            <template #aside>
              <b-button
                @click="showSections = false"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                class="btn-icon rounded-circle mr-50"
                v-b-tooltip.hover.top
                title="Back"
              >
                <feather-icon icon="ArrowLeftIcon" size="16" />
              </b-button>
            </template>

            <h1 class="mb-0">
              {{ currentItem.title }}
            </h1>
          </b-media>
        </b-col>
        <b-col md="12" class="mt-1">
          <b-button @click="openSection(0)" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Add Section</span>
          </b-button>
        </b-col>
      </b-row>

      <b-table
        :items="sectionData"
        :fields="secfields"
        show-empty
        responsive
        :busy="secLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(type)="data">
          {{ getType(data.value) }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Edit"
              @click="openSection(data.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.right
              title="Delete"
              size="16"
              @click="DeleteSec(data.item.id)"
            >
              <feather-icon size="16" icon="XIcon" />
            </b-button>
          </template>
        </template>
      </b-table>
    </b-card>

    <b-card no-body v-else>
      <b-row class="p-2">
        <b-col
          class="d-md-flex align-items-center justify-content-start"
          style="gap: 1.5rem"
        >
          <b-button @click="OpenAddModal(0)" variant="primary">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="text-nowrap">Add Page</span>
          </b-button>
          <b-form-input
            style="flex: 1;"
            v-model="searchQuery"
            class="d-inline-block mt-1 mt-md-0"
            placeholder="Search by title"
          />
        </b-col>
      </b-row>

      <b-table
        :items="filters"
        :fields="fields"
        show-empty
        responsive
        :busy="dataLoading"
      >
        <template #table-busy>
          <div class="text-center my-5">
            <b-spinner
              class="align-middle"
              type="grow"
              variant="primary"
              style="width: 3.5rem; height: 3.5rem;"
            ></b-spinner>
          </div>
        </template>

        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(actions)="data">
          <template>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Manage Page"
              @click="openPage(data.item)"
            >
              <feather-icon size="16" icon="SettingsIcon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-primary
              v-b-tooltip.placement.left
              title="Edit"
              @click="OpenAddModal(data.item)"
            >
              <feather-icon size="16" icon="EditIcon" />
            </b-button>

            <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="ml-1 btn-icon"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.placement.right
              title="Delete"
              size="16"
              @click="DeleteFlavour(data.item.id)"
            >
              <feather-icon size="16" icon="XIcon" />
            </b-button> -->
          </template>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BSidebar,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BMediaAside,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BSpinner,
    BFormCheckbox,
    BSidebar,
    BFormTextarea,
    vSelect,
    flatPickr,
    quillEditor,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      searchQuery: "",
      dataLoading: true,
      fields: [
        "#",
        { label: "title", key: "title" },
        { label: "status", key: "status" },
        "actions",
      ],
      items: [
        // {
        //   id: 1,
        //   title: "Home",
        //   status: "active",
        // },
        // {
        //   id: 2,
        //   title: "Projects",
        //   status: "active",
        // },
      ],
      request: false,
      myObj: {
        id: 0,
        title: "",
        status: "active",
      },
      sidebarTitle: "",

      statusOptions: [
        { text: "Active", value: "active" },
        { text: "In active", value: "inactive" },
      ],
      actionOptions: [
        { text: "Page", value: "page" },
        { text: "URL", value: "url" },
      ],
      formOptions: [
        { text: "Contact", value: "contact" },
        { text: "Volunteer", value: "volunteer" },
        { text: "Fundraising", value: "fundraising" },
      ],
      pageOptions: [
        // { id: 1, title: "Home" },
        // { id: 2, title: "About" },
        // { id: 3, title: "Projects" },
        // { id: 4, title: "Contact" },
      ],
      currentItem: {},
      showSections: false,
      allSections: [],
      sectionData: [],
      secfields: [
        "#",
        { label: "type", key: "type" },
        { label: "status", key: "status" },
        "actions",
      ],
      secObj: {},
      heroObj: {},
      projObj: {},
      actionObj: {},
      videoObj: {},
      fundObj: {},
      blogObj: {},
      splitObj: {},
      infoObj: {},
      contactObj: {},
      partObj: {},
      priorObj: {},
      donateObj: {},
      dcardObj: {},
      faqObj: {},
      cData: {},
      boxObj: {},
      overObj: {},
      quickObj: {},
      statsObj: {},
      colObj: {},
      bankObj: {},
      secLoading: false,
      typeOptions: [
        { text: "Banner", value: "Hero" },
        { text: "Donation Banner", value: "Donation Banner" },
        { text: "Donation Card", value: "Donation Card" },
        { text: "Quick Donation", value: "Quick Donation" },
        { text: "Carousel", value: "Carousel" },
        { text: "Projects", value: "Projects" },
        { text: "Video", value: "Video" },
        { text: "Take action", value: "Take action" },
        { text: "Fund Raiser", value: "Fund Raiser" },
        { text: "Blogs", value: "Blogs" },
        { text: "Stats", value: "Stats" },
        { text: "Split Section", value: "Split Section" },
        { text: "Info", value: "Info" },
        { text: "Overview", value: "Overview" },
        { text: "Contact Form", value: "Contact Form" },
        { text: "Partners", value: "Partners" },
        { text: "Priorities", value: "Priorities" },
        { text: "FAQs", value: "Faqs" },
        { text: "Colored Box", value: "Colored Box" },
        { text: "Bank Details", value: "Bank Details" },
        { text: "Column", value: "Column" },
      ],
      columnOptions: [1, 2, 3, 4],
      columnTypes: [
        // { text: "Donation Card", value: "Donation Card" },
        { text: "Video", value: "Video" },
        { text: "Info", value: "Info" },
        { text: "Contact Form", value: "Contact Form" },
        { text: "Partners", value: "Partners" },
        { text: "FAQs", value: "Faqs" },
        { text: "Colored Box", value: "Colored Box" },
      ],
      logoloading: false,
      imgLoading: false,
      vidLoading: false,
      projectOptions: [],
      saving: false,
      fundOptions: [],
      blogOptions: [],
      spLoading: false,
      infoLoading: false,
      caroLoading: false,
      currentIndex: 0,
      priorOptions: [],
      partOptions: [],
      dbLoading: false,
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "Enter details here",
      },
    };
  },
  created() {
    let obj = this.$store.state.pageRights.find(
      (el) => el.route == this.$route.name
    );
    if (!obj) {
      this.$router.replace({ name: "misc-not-authorized" });
    } else {
      this.LoadData();
      this.LoadProjects();
      this.LoadFunds();
      this.LoadBlogs();
      this.LoadPrior();
      this.LoadPartners();
    }
  },
  computed: {
    filters: function() {
      return this.items.filter((pro) => {
        return pro.title.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    isVideo(url) {
      return /\.(mp4)$/i.test(url);
    },
    getOptions(ind) {
      return {
        modules: {
          toolbar: `#quill-toolbar-${ind}`,
        },
        placeholder: "Enter details here",
      };
    },
    getType(val) {
      let obj = this.typeOptions.find((el) => el.value == val);
      return obj ? obj.text : "";
    },
    openPage(item) {
      this.currentItem = item;
      this.LoadSubs();
      this.showSections = true;
      // this.sectionData = this.allSections.filter((el) => el.pageID == this.currentItem.id);
    },
    LoadSubs() {
      this.secLoading = true;
      var config = {
        method: "get",
        url:
          "https://api.worldappeal.org.uk/api/Page/LoadSections?id=" +
          this.currentItem.id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.sectionData = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.secLoading = false));
    },
    openSection(id) {
      if (id == 0) {
        this.secObj = {
          id: 0,
          pageID: this.currentItem.id,
          type: "",
          status: "active",
          content: "",
        };
        this.heroObj = {
          cover: "",
          mobileCover: "",
          video: "",
          title: "",
          title2: "",
          buttonTitle: "",
          actionType: "",
          pageID: 0,
          url: "",
        };
        this.projObj = {
          title: "",
          projects: [],
          blocks: false,
          autoplay: false,
        };
        this.actionObj = {
          title: "",
          title2: "",
          details: "",
          buttonData: [
            // { title: "", actionType: "", page: "", pageID: 0, url: "" },
            // { title: "", actionType: "", page: "", pageID: 0, url: "" },
          ],
        };
        this.videoObj = {
          title: "",
          link: "",
        };
        this.fundObj = {
          title: "",
          fundIDs: [],
        };
        this.blogObj = {
          title: "",
          blogIDs: [],
        };
        this.splitObj = {
          title: "",
          image: "",
          details: "",
          titleRight: false,
          imageRight: false,
          animation: false,
          buttonTitle: "",
          actionType: "",
          pageID: 0,
          url: "",
        };
        this.infoObj = {
          title: "",
          isColored: false,
          watermark: "",
          details: "",
        };
        this.contactObj = {
          title: "",
          type: "",
        };
        this.partObj = {
          title: "",
          pIDs: [],
        };
        this.priorObj = {
          title: "",
          priorIDs: [],
        };
        this.donateObj = {
          image: "",
          title: "",
          amount: 0,
          initialAmount: 0,
          selected: "",
          link: "",
        };
        this.dcardObj = {
          title: "",
          amount: 0,
          initialAmount: 0,
          selected: "",
          showInfo: false,
          link: "",
        };
        this.faqObj = {
          title: "",
          faqs: [
            {
              text: "",
              answer: "",
              state: true,
            },
          ],
        };
        this.cData = {
          autoplay: false,
          pagination: false,
          arrows: false,
          images: [],
          slideData: [
            {
              image: "",
              heading: "",
              buttonTitle: "",
              actionType: "",
              pageID: 0,
              url: "",
            },
          ],
        };
        this.boxObj = {
          details: "",
        };
        this.overObj = {
          title: "",
          details: "",
          coloredDetails: "",
          moreDetails: "",
          lightDetails: "",
          faqtitle: "",
          faqs: [],
        };
        this.quickObj = {
          title: "",
          amount: 0,
          link: "",
        };
        this.statsObj = {
          title: "",
          stats: [{ text: "", count: "" }],
        };
        this.colObj = {
          columns: 1,
          sections: [],
        };
        for (let i = 0; i < 4; i++) {
          this.colObj.sections.push({
            type: "",
            dataObj: {
              card: {
                title: "",
                amount: 0,
                initialAmount: 0,
                selected: "",
                showInfo: false,
              },
              video: {
                title: "",
                link: "",
              },
              info: {
                title: "",
                isColored: false,
                watermark: "",
                details: "",
              },
              contact: {
                title: "",
                type: "",
              },
              partners: {
                title: "",
                pIDs: [],
              },
              faqs: {
                title: "",
                faqs: [
                  {
                    text: "",
                    answer: "",
                    state: true,
                  },
                ],
              },
              box: { details: "" },
            },
          });
        }

        this.bankObj = {
          title: "",
        };

        this.sidebarTitle = "Add Section";
        this.logoloading = false;
        this.imgLoading = false;
        this.vidLoading = false;
        this.spLoading = false;
        this.infoLoading = false;
        this.dbLoading = false;
        this.caroLoading = false;
        this.currentIndex = 0;
      } else {
        this.secObj = { ...id };
        this.sidebarTitle = "Edit Section";
        let obj = JSON.parse(this.secObj.content);
        this.heroObj = obj.hero;
        this.projObj = obj.projects;
        this.actionObj = obj.action;
        this.videoObj = obj.video;

        this.fundObj = obj.funds;
        if (!this.fundObj) {
          this.fundObj = {
            title: "",
            fundIDs: [],
          };
        }
        this.blogObj = obj.blogs;
        if (!this.blogObj) {
          this.blogObj = {
            title: "",
            blogIDs: [],
          };
        }

        this.splitObj = obj.splitData;
        if (!this.splitObj) {
          this.splitObj = {
            title: "",
            image: "",
            details: "",
            titleRight: false,
            imageRight: false,
            animation: false,
            buttonTitle: "",
            actionType: "",
            pageID: 0,
            url: "",
          };
        }

        this.infoObj = obj.infoData;
        if (!this.infoObj) {
          this.infoObj = {
            title: "",
            isColored: false,
            watermark: "",
            details: "",
          };
        }

        this.contactObj = obj.contact;
        if (!this.contactObj) {
          this.contactObj = {
            title: "",
            type: "",
          };
        }

        this.partObj = obj.partnerData;
        if (!this.partObj) {
          this.partObj = {
            title: "",
            pIDs: [],
          };
        }

        this.priorObj = obj.priorData;
        if (!this.priorObj) {
          this.priorObj = {
            title: "",
            priorIDs: [],
          };
        }

        this.donateObj = obj.donationBanner;
        if (!this.donateObj) {
          this.donateObj = {
            image: "",
            title: "",
            amount: 0,
            initialAmount: 0,
            selected: "",
            link: "",
          };
        }

        this.dcardObj = obj.donationCard;
        if (!this.dcardObj) {
          this.dcardObj = {
            title: "",
            amount: 0,
            initialAmount: 0,
            selected: "",
            showInfo: false,
            link: "",
          };
        }

        this.faqObj = obj.faqData;
        if (!this.faqObj) {
          this.faqObj = {
            title: "",
            faqs: [
              {
                text: "",
                answer: "",
                state: true,
              },
            ],
          };
        }

        this.cData = obj.carouselData;
        if (!this.cData) {
          this.cData = {
            autoplay: false,
            pagination: false,
            arrows: false,
            images: [],
            slideData: [
              {
                image: "",
                heading: "",
                buttonTitle: "",
                actionType: "",
                pageID: 0,
                url: "",
              },
            ],
          };
        }

        this.boxObj = obj.boxData;
        if (!this.boxObj) {
          this.boxObj = {
            details: "",
          };
        }

        this.overObj = obj.overviewData;
        if (!this.overObj) {
          this.overObj = {
            title: "",
            details: "",
            coloredDetails: "",
            moreDetails: "",
            lightDetails: "",
            faqtitle: "",
            faqs: [],
          };
        }

        this.quickObj = obj.quickData;
        if (!this.quickObj) {
          this.quickObj = {
            title: "",
            amount: 0,
            link: "",
          };
        }

        this.statsObj = obj.statsData;
        if (!this.statsObj) {
          this.statsObj = {
            title: "",
            stats: [{ text: "", count: "" }],
          };
        }

        this.colObj = obj.columnData;
        if (!this.colObj) {
          this.colObj = {
            columns: 1,
            sections: [],
          };
          for (let i = 0; i < 4; i++) {
            this.colObj.sections.push({
              type: "",
              dataObj: {
                card: {
                  title: "",
                  amount: 0,
                  initialAmount: 0,
                  selected: "",
                  showInfo: false,
                },
                video: {
                  title: "",
                  link: "",
                },
                info: {
                  title: "",
                  isColored: false,
                  watermark: "",
                  details: "",
                },
                contact: {
                  title: "",
                  type: "",
                },
                partners: {
                  title: "",
                  pIDs: [],
                },
                faqs: {
                  title: "",
                  faqs: [
                    {
                      text: "",
                      answer: "",
                      state: true,
                    },
                  ],
                },
                box: { details: "" },
              },
            });
          }
        } else {
          this.colObj.sections.forEach((el) => {
            if (!el.dataObj.partners) {
              el.dataObj.partners = {
                title: "",
                pIDs: [],
              };
            }
          });
        }

        this.bankObj = obj.bankDetails;
        if (!this.bankObj) {
          this.bankObj = {
            title: "",
          };
        }

        if (this.heroObj.cover) {
          this.logoloading = "loaded";
        } else this.logoloading = false;
        if (this.heroObj.mobileCover) {
          this.imgLoading = "loaded";
        } else this.imgLoading = false;
        if (this.heroObj.video) {
          this.vidLoading = "loaded";
        } else this.vidLoading = false;
        if (this.splitObj.image) {
          this.spLoading = "loaded";
        } else this.spLoading = false;
        if (this.infoObj.watermark) {
          this.infoLoading = "loaded";
        } else this.infoLoading = false;
        if (this.donateObj.image) {
          this.dbLoading = "loaded";
        } else this.dbLoading = false;
        this.caroLoading = false;
        this.currentIndex = 0;
      }
      this.$bvModal.show("modal-section");
    },
    CheckType() {
      var elem = this.$refs["type"];
      if (this.secObj.type == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCover() {
      if (this.secObj.type == "Hero") {
        var elem = this.$refs["image"];
        if (this.heroObj.cover == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckMobCover() {
      if (this.secObj.type == "Hero") {
        var elem = this.$refs["mob_image"];
        if (this.heroObj.mobileCover == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckHeroHeading() {
      if (this.secObj.type == "Hero") {
        var elem = this.$refs["hero_heading"];
        if (this.heroObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckHeroButton() {
      if (this.secObj.type == "Hero") {
        var elem = this.$refs["hero_button"];
        if (this.heroObj.buttonTitle == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckHeroRedirect() {
      if (this.secObj.type == "Hero") {
        var elem = this.$refs["hero_redirect"];
        if (this.heroObj.buttonTitle && this.heroObj.actionType == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckHeroPage() {
      if (this.secObj.type == "Hero" && this.heroObj.actionType == "page") {
        var elem = this.$refs["hero_page"];
        if (this.heroObj.buttonTitle && this.heroObj.pageID == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckHeroUrl() {
      if (this.secObj.type == "Hero" && this.heroObj.actionType == "url") {
        var elem = this.$refs["hero_url"];
        if (this.heroObj.buttonTitle && this.heroObj.url == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckProjTitle() {
      if (this.secObj.type == "Projects") {
        var elem = this.$refs["project_title"];
        if (this.projObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckProjSel() {
      if (this.secObj.type == "Projects") {
        var elem = this.$refs["project_sel"];
        if (this.projObj.projects.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckVidTitle() {
      if (this.secObj.type == "Video") {
        var elem = this.$refs["video_title"];
        if (this.videoObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckVidLink() {
      if (this.secObj.type == "Video") {
        var elem = this.$refs["video_link"];
        if (this.videoObj.link == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckAcTitle() {
      if (this.secObj.type == "Take action") {
        var elem = this.$refs["action_heading"];
        if (this.actionObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckAcDetails() {
      if (this.secObj.type == "Take action") {
        var elem = this.$refs["action_details"];
        if (this.actionObj.details == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckAcButtons() {
      if (this.secObj.type == "Take action") {
        let state = true;
        this.actionObj.buttonData.forEach((el) => {
          if (
            el.title == "" ||
            el.actionType == "" ||
            (el.actionType == "page" && el.pageID == 0) ||
            (el.actionType == "url" && el.url == "")
          ) {
            state = false;
          }
        });
        return state;
      } else return true;
    },
    CheckFundTitle() {
      if (this.secObj.type == "Fund Raiser") {
        var elem = this.$refs["fund_title"];
        if (this.fundObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckFundSel() {
      if (this.secObj.type == "Fund Raiser") {
        var elem = this.$refs["fund_sel"];
        if (this.fundObj.fundIDs.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckBlogTitle() {
      if (this.secObj.type == "Blogs") {
        var elem = this.$refs["blog_title"];
        if (this.blogObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckBlogSel() {
      if (this.secObj.type == "Blogs") {
        var elem = this.$refs["blog_sel"];
        if (this.blogObj.blogIDs.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSpImage() {
      if (this.secObj.type == "Split Section") {
        var elem = this.$refs["sp_image"];
        if (this.splitObj.image == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSpTitle() {
      if (this.secObj.type == "Split Section") {
        var elem = this.$refs["sp_title"];
        if (this.splitObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSpDetails() {
      if (this.secObj.type == "Split Section") {
        var elem = this.$refs["sp_details"];
        if (this.splitObj.details == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSpAction() {
      if (this.secObj.type == "Split Section") {
        var elem = this.$refs["sp_redirect"];
        if (this.splitObj.buttonTitle && !this.splitObj.actionType) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSpPage() {
      if (
        this.secObj.type == "Split Section" &&
        this.splitObj.actionType == "page"
      ) {
        var elem = this.$refs["sp_page"];
        if (
          this.splitObj.buttonTitle &&
          (!this.splitObj.pageID || this.splitObj.pageID == 0)
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSpUrl() {
      if (
        this.secObj.type == "Split Section" &&
        this.splitObj.actionType == "url"
      ) {
        var elem = this.$refs["sp_url"];
        if (this.splitObj.buttonTitle && !this.splitObj.url) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckInfoImage() {
      if (this.secObj.type == "Info") {
        var elem = this.$refs["info_image"];
        if (this.infoObj.watermark == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckInfoTitle() {
      if (this.secObj.type == "Info") {
        var elem = this.$refs["info_title"];
        if (this.infoObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckInfoDetails() {
      if (this.secObj.type == "Info") {
        var elem = this.$refs["info_details"];
        if (this.infoObj.details == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckCTitle() {
      if (this.secObj.type == "Contact Form") {
        var elem = this.$refs["c_title"];
        if (this.contactObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckCType() {
      if (this.secObj.type == "Contact Form") {
        var elem = this.$refs["c_type"];
        if (this.contactObj.type == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckPTitle() {
      if (this.secObj.type == "Partners") {
        var elem = this.$refs["part_title"];
        if (this.partObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckPSelected() {
      if (this.secObj.type == "Partners") {
        var elem = this.$refs["part_sel"];
        if (!this.partObj.pIDs || this.partObj.pIDs.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckPrTitle() {
      if (this.secObj.type == "Priorities") {
        var elem = this.$refs["prior_title"];
        if (this.priorObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckPrSel() {
      if (this.secObj.type == "Priorities") {
        var elem = this.$refs["prior_sel"];
        if (this.priorObj.priorIDs.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckDbImage() {
      if (this.secObj.type == "Donation Banner") {
        var elem = this.$refs["db_image"];
        if (this.donateObj.image == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckDTitle() {
      if (this.secObj.type == "Donation Banner") {
        var elem = this.$refs["db_title"];
        if (this.donateObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkDAmount() {
      if (this.secObj.type == "Donation Banner") {
        var elem = this.$refs["db_amount"];
        if (
          this.donateObj.initialAmount == "" ||
          this.donateObj.initialAmount <= 0
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckDCTitle() {
      if (this.secObj.type == "Donation Card") {
        var elem = this.$refs["dc_title"];
        if (this.dcardObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    checkDCAmount() {
      if (this.secObj.type == "Donation Card") {
        var elem = this.$refs["dc_amount"];
        if (
          this.dcardObj.initialAmount == "" ||
          this.dcardObj.initialAmount <= 0
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckFaqTitle() {
      if (this.secObj.type == "Faqs") {
        var elem = this.$refs["faq_title"];
        if (this.faqObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckFaqData() {
      if (this.secObj.type == "Faqs") {
        let state = true;
        this.faqObj.faqs.forEach((el) => {
          if (el.text == "" || el.answer == "") {
            state = false;
          }
        });
        return state;
      } else return true;
    },
    CheckCarousel() {
      if (this.secObj.type == "Carousel") {
        var elem = this.$refs["car_img"];
        if (this.cData.images.length == 0) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckCaroImage() {
      if (this.secObj.type == "Carousel") {
        let state = true;
        this.cData.slideData.forEach((el) => {
          if (
            el.image == "" ||
            (el.buttonTitle != "" && el.actionType == "") ||
            (el.buttonTitle != "" &&
              el.actionType == "page" &&
              el.pageID == 0) ||
            (el.buttonTitle != "" && el.actionType == "url" && el.url == "")
          ) {
            state = false;
          }
        });
        return state;
      } else return true;
    },
    CheckBoxDetails() {
      if (this.secObj.type == "Colored Box") {
        var elem = this.$refs["box_details"];
        if (this.boxObj.details == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckBankTitle() {
      if (this.secObj.type == "Bank Details") {
        var elem = this.$refs["bank_title"];
        if (this.bankObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckOverTitle() {
      if (this.secObj.type == "Overview") {
        var elem = this.$refs["ov_title"];
        if (this.overObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckOverDetails() {
      if (this.secObj.type == "Overview") {
        var elem = this.$refs["ov_details"];
        if (
          this.overObj.details == "" &&
          this.overObj.coloredDetails == "" &&
          this.overObj.moreDetails == "" &&
          this.overObj.lightDetails == ""
        ) {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckOverFaqTitle() {
      if (this.secObj.type == "Overview") {
        var elem = this.$refs["ov_ftitle"];
        if (this.overObj.faqs.length > 0 && this.overObj.faqtitle == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckOverFaqData() {
      if (this.secObj.type == "Overview") {
        let state = true;
        this.overObj.faqs.forEach((el) => {
          if (el.text == "" || el.answer == "") {
            state = false;
          }
        });
        return state;
      } else return true;
    },
    CheckQTitle() {
      if (this.secObj.type == "Quick Donation") {
        var elem = this.$refs["q_title"];
        if (this.quickObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSTitle() {
      if (this.secObj.type == "Stats") {
        var elem = this.$refs["st_title"];
        if (this.statsObj.title == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    CheckSData() {
      if (this.secObj.type == "Stats") {
        let state = true;
        this.statsObj.stats.forEach((el) => {
          if (el.text == "" || el.count == "") {
            state = false;
          }
        });
        return state;
      } else return true;
    },
    CheckColData() {
      if (this.secObj.type == "Column") {
        let state = true;
        this.colObj.sections.forEach((el, ind) => {
          if (ind < this.colObj.columns) {
            let elem = this.$refs[`col_type${ind}`][0];
            if (el.type == "") {
              state = false;
              elem.state = false;
            } else {
              elem.state = true;
              // if (el.type == "Donation Card") {
              //   let elemc1 = this.$refs[`col_c_title${ind}`][0];
              //   let elemc2 = this.$refs[`col_c_amount${ind}`][0];

              //   if (el.dataObj.card.title == "") {
              //     state = false;
              //     elemc1.state = false;
              //   } else elemc1.state = true;
              //   if (
              //     el.dataObj.card.initialAmount == "" ||
              //     el.dataObj.card.initialAmount <= 0
              //   ) {
              //     state = false;
              //     elemc2.state = false;
              //     el.dataObj.card.initialAmount = 0;
              //   } else {
              //     elemc2.state = true;
              //     el.dataObj.card.initialAmount = parseInt(
              //       el.dataObj.card.initialAmount
              //     );
              //   }
              //   el.dataObj.card.amount = el.dataObj.card.initialAmount;
              //   el.dataObj.card.selected = el.dataObj.card.initialAmount;
              // }
              if (el.type == "Video") {
                let elemv1 = this.$refs[`col_v_title${ind}`][0];
                let elemv2 = this.$refs[`col_v_link${ind}`][0];

                if (el.dataObj.video.title == "") {
                  state = false;
                  elemv1.state = false;
                } else elemv1.state = true;
                if (el.dataObj.video.link == "") {
                  state = false;
                  elemv2.state = false;
                } else elemv2.state = true;
              } else if (el.type == "Info") {
                let elemin1 = this.$refs[`col_in_title${ind}`][0];
                let elemin2 = this.$refs[`col_in_details${ind}`][0];

                if (el.dataObj.info.title == "") {
                  state = false;
                  elemin1.state = false;
                } else elemin1.state = true;
                if (el.dataObj.info.details == "") {
                  state = false;
                  elemin2.state = false;
                } else elemin2.state = true;
              } else if (el.type == "Contact Form") {
                let elemcf1 = this.$refs[`col_cf_title${ind}`][0];
                let elemcf2 = this.$refs[`col_cf_type${ind}`][0];

                if (el.dataObj.contact.title == "") {
                  state = false;
                  elemcf1.state = false;
                } else elemcf1.state = true;
                if (el.dataObj.contact.type == "") {
                  state = false;
                  elemcf2.state = false;
                } else elemcf2.state = true;
              } else if (el.type == "Partners") {
                let elemcp1 = this.$refs[`col_part_title${ind}`][0];
                let elemcp2 = this.$refs[`col_part_ids${ind}`][0];
                if (el.dataObj.partners.title == "") {
                  state = false;
                  elemcp1.state = false;
                } else elemcp1.state = true;
                if (el.dataObj.partners.pIDs.length == 0) {
                  state = false;
                  elemcp2.state = false;
                } else elemcp2.state = true;
              } else if (el.type == "Faqs") {
                let elemf1 = this.$refs[`col_f_title${ind}`][0];

                if (el.dataObj.faqs.title == "") {
                  state = false;
                  elemf1.state = false;
                } else elemf1.state = true;

                el.dataObj.faqs.faqs.forEach((ff) => {
                  if (ff.text == "" || ff.answer == "") {
                    state = false;
                  }
                });
              } else if (el.type == "Colored Box") {
                let elemb1 = this.$refs[`col_b_details${ind}`][0];

                if (el.dataObj.box.details == "") {
                  state = false;
                  elemb1.state = false;
                } else elemb1.state = true;
              }
            }
          }
        });
        return state;
      } else return true;
    },
    AddSt() {
      this.statsObj.stats.push({
        text: "",
        count: "",
      });
    },
    removeSt(ind) {
      this.statsObj.stats.splice(ind, 1);
    },
    AddVal() {
      this.actionObj.buttonData.push({
        title: "",
        actionType: "",
        page: "",
        pageID: 0,
        url: "",
      });
    },
    removeVal(ind) {
      this.actionObj.buttonData.splice(ind, 1);
    },
    AddFaq() {
      this.faqObj.faqs.push({
        text: "",
        answer: "",
        state: false,
      });
    },
    removeFaq(ind) {
      this.faqObj.faqs.splice(ind, 1);
    },
    addSlide() {
      this.cData.slideData.push({
        image: "",
        heading: "",
        buttonTitle: "",
        actionType: "",
        pageID: 0,
        url: "",
      });
    },
    removeSlide(ind) {
      this.cData.slideData.splice(ind, 1);
    },
    AddColFaq(ind) {
      this.colObj.sections[ind].dataObj.faqs.faqs.push({
        text: "",
        answer: "",
        state: false,
      });
    },
    removeColFaq(ind, fInd) {
      this.colObj.sections[ind].dataObj.faqs.faqs.splice(fInd, 1);
    },
    selectlogo() {
      let obj = this.$refs.fileProfile.files[0];

      if (obj !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.heroObj.cover = res;
            this.logoloading = "loaded";
            this.CheckCover();
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },
    deleteLogo() {
      this.heroObj.cover = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectMob() {
      let obj = this.$refs.mobProfile.files[0];

      if (obj !== "") {
        this.imgLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.heroObj.mobileCover = res;
            this.imgLoading = "loaded";
            this.CheckMobCover();
          })
          .catch((err) => {
            this.imgLoading = false;
            console.log(err);
          });
      }
    },
    deleteMob() {
      this.heroObj.mobileCover = "";
      this.imgLoading = false;
      this.$refs.mobProfile.value = null;
    },
    selectVid() {
      let obj = this.$refs.vid.files[0];

      if (obj !== "") {
        this.vidLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.heroObj.video = res;
            this.vidLoading = "loaded";
          })
          .catch((err) => {
            this.vidLoading = false;
            console.log(err);
          });
      }
    },
    deleteVid() {
      this.heroObj.video = "";
      this.vidLoading = false;
      this.$refs.vid.value = null;
    },
    selectSp() {
      let obj = this.$refs.spImage.files[0];

      if (obj !== "") {
        this.spLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.splitObj.image = res;
            this.spLoading = "loaded";
            this.CheckSpImage();
          })
          .catch((err) => {
            this.spLoading = false;
            console.log(err);
          });
      }
    },
    deleteSp() {
      this.splitObj.image = "";
      this.spLoading = false;
      this.$refs.spImage.value = null;
    },
    selectInfo() {
      let obj = this.$refs.infoImage.files[0];

      if (obj !== "") {
        this.infoLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.infoObj.watermark = res;
            this.infoLoading = "loaded";
            // this.CheckInfoImage();
          })
          .catch((err) => {
            this.infoLoading = false;
            console.log(err);
          });
      }
    },
    deleteInfo() {
      this.infoObj.watermark = "";
      this.infoLoading = false;
      this.$refs.infoImage.value = null;
    },
    selectDImg() {
      let obj = this.$refs.dbImg.files[0];

      if (obj !== "") {
        this.dbLoading = true;
        let formData = new FormData();
        formData.append("file", obj);
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;

            this.donateObj.image = res;
            this.dbLoading = "loaded";
            this.CheckDbImage();
          })
          .catch((err) => {
            this.dbLoading = false;
            console.log(err);
          });
      }
    },
    deleteDImg() {
      this.donateObj.image = "";
      this.dbLoading = false;
      this.$refs.dbImg.value = null;
    },
    selectCaro(item, ind) {
      let obj = this.$refs["cImage" + ind][0].files;

      if (obj.length > 0) {
        this.currentIndex = ind;
        this.caroLoading = true;
        let formData = new FormData();
        formData.append("file", obj[0]);
        // obj.forEach((el) => {
        //   formData.append("file", el);
        // });
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let res = e.data.myresp[0].path;
            item.image = res;
            // res.forEach((el) => {
            //   this.cData.images.push(el.path);
            // });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.caroLoading = false));
      }
    },
    deleteCaro(ind) {
      this.cData.images.splice(ind, 1);
    },
    AddOvFaq() {
      this.overObj.faqs.push({
        text: "",
        answer: "",
        state: false,
      });
    },
    removeOvFaq(ind) {
      this.overObj.faqs.splice(ind, 1);
    },
    saveSection() {
      this.CheckType();
      this.CheckCover();
      this.CheckMobCover();
      // this.CheckHeroHeading();
      // this.CheckHeroButton();
      this.CheckHeroRedirect();
      this.CheckHeroPage();
      this.CheckHeroUrl();
      this.CheckProjTitle();
      this.CheckProjSel();
      this.CheckVidTitle();
      this.CheckVidLink();
      this.CheckAcTitle();
      this.CheckAcDetails();
      this.CheckFundTitle();
      this.CheckFundSel();
      this.CheckBlogTitle();
      this.CheckBlogSel();
      this.CheckSpImage();
      this.CheckSpTitle();
      this.CheckSpDetails();
      this.CheckSpAction();
      this.CheckSpPage();
      this.CheckSpUrl();
      // this.CheckInfoImage();
      this.CheckInfoTitle();
      this.CheckInfoDetails();
      this.CheckCTitle();
      this.CheckCType();
      this.CheckPTitle();
      this.CheckPrTitle();
      this.CheckPrSel();
      this.CheckDbImage();
      this.CheckDTitle();
      this.checkDAmount();
      this.CheckDCTitle();
      this.checkDCAmount();
      this.CheckFaqTitle();
      this.CheckOverTitle();
      this.CheckOverDetails();
      this.CheckOverFaqTitle();
      this.CheckQTitle();
      this.CheckSTitle();
      this.CheckBankTitle();
      if (
        this.CheckType() == false ||
        this.CheckCover() == false ||
        this.CheckMobCover() == false ||
        // this.CheckHeroHeading() == false ||
        // this.CheckHeroButton() == false ||
        this.CheckHeroRedirect() == false ||
        this.CheckHeroPage() == false ||
        this.CheckHeroUrl() == false ||
        this.CheckProjTitle() == false ||
        this.CheckProjSel() == false ||
        this.CheckVidTitle() == false ||
        this.CheckVidLink() == false ||
        this.CheckAcTitle() == false ||
        this.CheckAcDetails() == false ||
        this.CheckAcButtons() == false ||
        this.CheckFundTitle() == false ||
        this.CheckFundSel() == false ||
        this.CheckBlogTitle() == false ||
        this.CheckBlogSel() == false ||
        this.CheckSpImage() == false ||
        this.CheckSpTitle() == false ||
        this.CheckSpDetails() == false ||
        this.CheckSpAction() == false ||
        this.CheckSpPage() == false ||
        this.CheckSpUrl() == false ||
        // this.CheckInfoImage() == false ||
        this.CheckInfoTitle() == false ||
        this.CheckInfoDetails() == false ||
        this.CheckCTitle() == false ||
        this.CheckCType() == false ||
        this.CheckPTitle() == false ||
        this.CheckPSelected() == false ||
        this.CheckPrTitle() == false ||
        this.CheckPrSel() == false ||
        this.CheckDbImage() == false ||
        this.CheckDTitle() == false ||
        this.checkDAmount() == false ||
        this.CheckDCTitle() == false ||
        this.checkDCAmount() == false ||
        this.CheckFaqTitle() == false ||
        this.CheckFaqData() == false ||
        // this.CheckCarousel() == false ||
        this.CheckCaroImage() == false ||
        this.CheckBoxDetails() == false ||
        this.CheckOverTitle() == false ||
        this.CheckOverDetails() == false ||
        this.CheckOverFaqTitle() == false ||
        this.CheckOverFaqData() == false ||
        this.CheckQTitle() == false ||
        this.CheckSTitle() == false ||
        this.CheckSData() == false ||
        this.CheckColData() == false ||
        this.CheckBankTitle() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.saving = true;

        if (
          this.donateObj.initialAmount == "" ||
          this.donateObj.initialAmount <= 0
        ) {
          this.donateObj.initialAmount = 0;
        } else {
          this.donateObj.initialAmount = parseInt(this.donateObj.initialAmount);
        }
        this.donateObj.amount = this.donateObj.initialAmount;
        this.donateObj.selected = this.donateObj.initialAmount;

        if (
          this.dcardObj.initialAmount == "" ||
          this.dcardObj.initialAmount <= 0
        ) {
          this.dcardObj.initialAmount = 0;
        } else {
          this.dcardObj.initialAmount = parseInt(this.dcardObj.initialAmount);
        }
        this.dcardObj.amount = this.dcardObj.initialAmount;
        this.dcardObj.selected = this.dcardObj.initialAmount;

        this.faqObj.faqs[0].state = true;

        let data = {
          hero: this.heroObj,
          projects: this.projObj,
          action: this.actionObj,
          video: this.videoObj,
          funds: this.fundObj,
          blogs: this.blogObj,
          splitData: this.splitObj,
          infoData: this.infoObj,
          contact: this.contactObj,
          partnerData: this.partObj,
          priorData: this.priorObj,
          donationBanner: this.donateObj,
          donationCard: this.dcardObj,
          faqData: this.faqObj,
          carouselData: this.cData,
          boxData: this.boxObj,
          overviewData: this.overObj,
          quickData: this.quickObj,
          statsData: this.statsObj,
          columnData: this.colObj,
          bankDetails: this.bankObj,
        };
        this.secObj.content = JSON.stringify(data);
        // console.log(this.secObj);
        if (this.secObj.id == 0) {
          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/PageSections",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.secObj,
          };

          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                Swal.fire("Success!", "Section added successfully.", "success");
                this.LoadSubs();
                this.$bvModal.hide("modal-section");
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
          // this.secObj.id = Math.max(...this.allSections.map((obj) => obj.id), 0) + 1;
          // this.sectionData.push(this.secObj);
          // this.allSections.push(this.secObj);
        } else {
          var config = {
            method: "put",
            url:
              "https://api.worldappeal.org.uk/api/PageSections/" +
              this.secObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.secObj,
          };
          axios(config)
            .then((response) => {
              this.saving = false;
              if (response.data.status === "success") {
                this.LoadSubs();
                this.$bvModal.hide("modal-section");
                // console.log(response.data);

                this.$bvToast.toast("Section updated successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.saving = false;
              console.log(error);
            });
          // let obj = this.sectionData.findIndex((el) => el.id == this.secObj.id);
          // this.sectionData.splice(obj, 1, this.secObj);
          // let obj2 = this.allSections.findIndex((el) => el.id == this.secObj.id);
          // this.allSections.splice(obj, 1, this.secObj);
        }
      }
    },
    DeleteSec(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.sectionData = this.sectionData.filter((el) => el.id != id);
          // this.allSections = this.allSections.filter((el) => el.id != id);
          // this.$bvToast.toast("Section deleted successfully!", {
          //   title: "Success!",
          //   variant: "success",
          //   toaster: "b-toaster-top-center",
          // });

          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/PageSections/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Section has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadSubs();
                });
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },

    CheckName() {
      var elem = this.$refs["name"];
      if (
        this.myObj.title
          .replace(/-/g, " ")
          .replace(/\s+/g, " ")
          .trim() == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkStatus() {
      var elem = this.$refs["status"];
      if (this.myObj.status == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    OpenAddModal(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          title: "",
          status: "active",
        };
        this.sidebarTitle = "Add Page";
      } else {
        this.myObj = { ...id };
        this.sidebarTitle = "Edit Page";
      }
      this.$bvModal.show("modal-menu");
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // this.items = this.items.filter((el) => el.id != id);
          // this.$bvToast.toast("Page deleted successfully!", {
          //   title: "Success!",
          //   variant: "success",
          //   toaster: "b-toaster-top-center",
          // });

          var config = {
            method: "delete",
            url: "https://api.worldappeal.org.uk/api/Page/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          axios(config)
            .then((response) => {
              if (response.data.status === "success") {
                Swal.fire("Success!", "Page has been deleted.", "success").then(
                  (res) => {
                    this.LoadData();
                  }
                );
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },

    Add() {
      this.CheckName();
      this.checkStatus();

      if (this.CheckName() == false || this.checkStatus() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        this.myObj.title = this.myObj.title
          .replace(/-/g, " ")
          .replace(/\s+/g, " ")
          .trim();
        console.log(this.myObj);

        if (this.myObj.id == 0) {
          // this.myObj.id = Math.max(...this.items.map((obj) => obj.id), 0) + 1;
          // this.items.push(this.myObj);

          var config = {
            method: "post",
            url: "https://api.worldappeal.org.uk/api/Page",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              this.request = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                Swal.fire("Success!", "Page added successfully.", "success");
                this.LoadData();
                this.$bvModal.hide("modal-menu");
              } else {
                this.$bvToast.toast("Something went wrong!", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.request = false;
              console.log(error);
            });
        } else {
          // let obj = this.items.findIndex((el) => el.id == this.myObj.id);
          // this.items.splice(obj, 1, this.myObj);

          var config = {
            method: "put",
            url: "https://api.worldappeal.org.uk/api/Page/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              this.request = false;
              if (response.data.status === "success") {
                // console.log(response.data);
                this.$bvModal.hide("modal-menu");
                this.LoadData();

                this.$bvToast.toast("Page updated successfully!", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-top-center",
                });
              }
            })
            .catch(function(error) {
              this.request = false;
              console.log(error);
            });
        }
      }
    },
    LoadData() {
      this.dataLoading = true;
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Page",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.items = res.data.data;
          this.pageOptions = this.items;
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (this.dataLoading = false));
    },
    LoadProjects() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Project",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.projectOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadFunds() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Fundraiser",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.fundOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadBlogs() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Blogs",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.blogOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadPrior() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Priorities",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };
      axios(config)
        .then((res) => {
          // console.log(res.data.data);
          this.priorOptions = res.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    LoadPartners() {
      var config = {
        method: "get",
        url: "https://api.worldappeal.org.uk/api/Partners",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(response);
          this.partOptions = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

.scrolling-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.scrolling-wrapper .moreImg {
  margin-right: 8px;
  position: relative;
}
.my-close {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

#quil-content ::v-deep {
  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
